<template>
    <nav class="container">
            <img src="@/assets/modern.svg" height="15" alt="">
            <router-link to="/"  class="nav-a">Главная</router-link>
            <router-link to="/detect" class="nav-a">Распознать лицо</router-link>
            <router-link to="/register"  class="nav-a" v-if="$store.state.user">Занести в базу</router-link>
            <router-link to="/top"  class="nav-a">Рейтинг</router-link>
            <router-link to="/authorize"  class="nav-a">{{($store.state.user) ? 'Личный кабинет сотрудника' : 'Вход сотрудника'}}</router-link>
    </nav>
</template>
<script>
export default{
    name : "heade-rcomp",

}
</script>
<style scoped>
    nav{
        background: #ef3030;
        height: 50px;
    }
    nav{
        display: flex;
        align-items: center;
        gap: 20px;
        height: 100%;
        padding: 20px;
        margin-top: 5px;
        border-radius: 10px;
    }
    .nav-a{
        text-decoration: none;
        color: #ccc;
        opacity: 0.9;
    }
    .nav-a:hover{
        opacity: 1;
    }
    .router-link-active{
        opacity: 1;
        color: #fff;
    }
</style>