import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globals from './globals/globals'
let app = createApp(App)
for(let global in globals.globals){
    console.log(globals.globals[global]);
    app.config.globalProperties[global] = globals.globals[global];
}

app.use(store).use(router).mount('#app')
