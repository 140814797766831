const globals = {
    $databaseServerAPI : (process.env.NODE_ENV === "development") ? "http://localhost:3000/api/v1" : "https://s3.ai.ecit.ru/api/v1",
    $formatName : (fullName) =>{
        const parts = fullName.toLowerCase().split(" ");
        const formattedParts = [];
        for (let part of parts) {
            formattedParts.push(part.charAt(0).toUpperCase() + part.slice(1));
        }
        const formattedFullName = formattedParts.join(" ");
        return formattedFullName;
    }
}

export default {
    globals
}