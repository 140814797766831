<template>
  <div class="home">
    <div class="container test">
        <h2>Платформа АКТИВКОИН</h2>
        <p>Версия платформы: 1.0.0</p>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    
  }
}
</script>
<style scoped>
.test{
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  margin-top: 10px;
  box-sizing: border-box;
}
</style>
