<template>
  <Header/>
  <router-view/>
</template>
<script>
import Header from './components/Header.vue';


export default{
  name : "AppView",
  components : {
    Header
  },
  mounted(){
    if(localStorage.getItem("token")){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
      fetch(`${this.$databaseServerAPI}/auth/check`,{
        method : "POST",
        headers : myHeaders
      }).then((res)=>{
        res.json().then((data)=>{
          if(data.message == "ok") this.$store.state.user = data.users;
        })
      })
    }
  }
}
</script>
<style>
  @import url("https://cdn.w3bwave.studio/studio/assets/fonts/interface/stylesheet.css");
  html,body{
    width: 100%;
    height: 100%;
  }
  body{
    margin: 0;
    padding: 0;
    font-family: 'TT Interfaces';
    background: #f6f6f6;
  }
  .container{
    width: 1200px;
    margin: 0 auto;
  }

  input{
    padding: 5px 10px;
    outline: none;
    font-family: 'TT Interfaces';
    border: none;
    border-radius: 5px;
    font-size: 16px;
  }
  button{
    background: #e94307;
    border-radius: 10px;
    padding: 10px;
    border: none;
    color: #fff;
    font-family: 'TT Interfaces';
    text-transform: uppercase;
  }
  button:hover{
    filter: brightness(0.9);
  }

  .flex-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .row{
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    text-align: left;
  }
</style>
